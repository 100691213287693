/* Base styles */
.NavBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  background-color: #282C34;
  height: auto;
  padding: 0 20px;
}

.points-and-instructions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}

.points-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-right: 20px;
  color: white
}

.instruction-button {
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgb(28, 48, 73);
  color: #ffffff;
  padding: 0;
  box-sizing: border-box;
  margin: 5px;
}

.emphasize {
  font-weight: bold;
  color: #f66a00;
}

.game-title {
  color: white;
  font: 24px 'Arial', sans-serif; /* Combined font properties */
  font-weight: 100;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(28, 48, 73);
  padding: 20px;
  z-index: 1000;
  color: white;
  text-align: left;
  border: 10px solid #ff8c00;
  border-radius: 10px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  overflow-y: auto; /* Allow scrolling inside the modal if the content exceeds its height */
  max-width: 100%; /* Limit modal width to not exceed 90% of the viewport width */
  height: 100%;
  max-height: 70vh; /* Limit modal height to not exceed 90% of the viewport height */
  overflow-y: auto;

::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.points-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-left: 10px;
}

.points {
  font-size: 1.9em;
  padding: 1px;
  text-transform: uppercase;
}

/* Mobile styles */
@media (max-width: 600px) {
  .NavBar {
    padding: 0px 15px; /* Slightly reduce side padding */
  }

  .modal {
    width: 100vw; /* Use most of the viewport width */
    max-width:100vw; /* Specifically tailored to fit iPhone 11 Pro's width */
    padding: 15px; /* Reduce padding to save space */
    font-size: 18px; /* Adjust font size for better fit */
    line-height: 1.2; /* Improve readability with adjusted line height */
    overflow-y: auto; /* Ensure content can scroll if it overflows the modal's height */
    max-height: 85vh; /* Limit the max height to 80% of the viewport height */
  }

  .modal p, .modal h3 {
    font-size: 0.8rem; /* Further reduce font size for text elements */
    margin-top: 0.5rem; /* Reduce margins to fit more content */
    margin-bottom: 0.5rem; /* Adjust bottom margins as well */
  }

  .instruction-button {
    font-size: 22px; /* Reduce button font size slightly */
    width: 35px; /* Adjust button width */
    height: 35px; /* Adjust button height */
    margin: 3px; /* Tighten margins around buttons */
  }

  .points-and-instructions {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  /* Ensure that critical action items like close buttons are easily accessible */
  .close-button {
    padding: 5px 8px; /* Adjust padding for better touch targets */
  }
  .points {
    font-size: 1.2rem!important;
  }
}


/* Desktop and larger devices */
@media (min-width: 601px) {
  .NavBar {
    padding: 0 40px;
  }

  .points-and-instructions {
    flex-direction: row;
    justify-content: end;
    align-items: center;
  }

  .instruction-button {
    top: 20px;
    margin: 10px;
  }

  .modal {
    width: 50vw; /* Use a larger percentage of the viewport width on larger screens */
    max-width: 600px; /* Cap modal width to a maximum */
    height: auto; /* Adjust height based on content, with a maximum limit */
    max-height: 90vh; /* Prevent modal from exceeding 80% of viewport height */
    padding: 20px;
    font-size: 18px; /* Increase font size for readability on larger screens */
  }

  .modal p, .modal h3 {
    font-size: 1rem; /* Adjust text size for larger screens */
  }
}

/* Adjustments for very small screens like iPhone SE and iPhone 5 */
@media (max-width: 320px) {
  .modal {
    width: 100vw; /* Use most of the viewport width */
    max-width: 100vw; /* Specifically tailored to fit iPhone 5's width */
    padding: 10px; /* Reduce padding to save space */
    font-size: 14px; /* Slightly reduce font size for better fit */
    line-height: 1.2; /* Improve readability with adjusted line height */
    overflow-y: auto; /* Ensure content can scroll if it overflows the modal's height */
    max-height: 100vh; /* Prevent the modal from exceeding the viewport height */
  }

  .modal p, .modal h3 {
    font-size: 0.75rem; /* Further reduce text size for small screens */
  }

  /* Adjust button size and spacing for smaller screens */
  .instruction-button {
    width: 35px; /* Reduce button size */
    height: 35px; /* Maintain aspect ratio */
    font-size: 20px; /* Adjust icon size to fit button */
    margin: 5px; /* Reduce margin around buttons to save space */
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff647c;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 8px;
}

.points-container {
  padding: 15px;
  background-color: #38404a; /* Adjust background color as desired */
  border: 2px solid #ffffff; /* White border */
  border-radius: 10px; /* Rounded corners */
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.points {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #ffffff; /* Keep the text color white */
  animation: scale-up 0.3s ease-in-out;
}


.medal-icon {
  font-size: 24px; /* Adjust the size of the medal icon */
  color: #ffd700; /* Gold color for the medal */
}

/* .points {
  font-size: 20px;
  font-weight: bold;
  animation: scale-up 0.3s ease-in-out;
} */

@keyframes scale-up {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}