.gameBoardContainer {
  display: flex;
  flex-direction: column; /* Stays the same, ensures vertical stacking of children */
  max-height: 100vh; /* Ensures the container takes the full viewport height */
  justify-content: center; /* Centers content vertically in the container */
  align-items: center; /* Centers content horizontally */
  overflow: hidden;
}

.letterSet.spinning,
.letterSet.correct,
.letterSet.incorrect {
  animation: spin 1s linear;
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.gameBoard {
    font-family: 'Arial, sans-serif';
    padding: 100px;
    max-width: 600px;
    margin: 0 auto;
    height: 100%; /* Add this line */
    flex-direction: column; /* Add this line */
    justify-content: space-between; /* Add this line */
    align-items: center;
}
  
  .letterSetContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    /* gap: 10px; adjust as needed */
  }
  
.letterSet {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 10px;
    margin: 5px;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    background-color: #0000FF;
    color: white;
    cursor: pointer;
  }
  
.inputStyle {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: calc(100% - 22px);
    font-size: 16px;
  }
  .inputContainer {
    display: flex;
    position: relative; /* Allows absolute positioning inside */
  }
  
  .inputStyle {
    flex-grow: 1; /* Takes up the majority of the space */
    /* Additional styling here */
  }
  
  .clearButton {
    position: absolute;
    right: 5px; /* Adjust based on your design */
    top: 50%;
    font-size: 19px;
    transform: translateY(-50%); /* Center vertically */
    background: transparent;
    border: none;
    cursor: pointer;
    background-color: rgb(226, 226, 226);
    padding: 7px;
    border-radius: 10px;
    /* Adding shadow */
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Horizontal offset, vertical offset, blur radius, color */
    color: black; /* Default text color */
  }
  
  /* Ensuring text color doesn't change upon interaction */
  .clearButton:hover,
  .clearButton:active,
  .clearButton:focus,
  .clearButton:visited {
    color: black; /* Maintains text color consistency */
    text-decoration: none; /* Optional: Removes underline from links */
  }
  
  

  .buttonContainer {
    display: flex;
    justify-content: space-between;
}

.buttonCommon {
  width: 49%; /* Change width to 40% */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold; /* Add this line to make the font bold */
}

.submitButton {
  background-color: #2E8540;
}

.hintButton {
  background-color: #0000FF; /* Change color if you want the hint button to look different */
}
  
.guessedWordsStyle {
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .wordsToFind {
    background-color: #ccc;
    border-radius: 10px;
    padding: 10px;
  }

  .messageContainer {
    font-size: 24px; /* Adjust the font size */
    position: fixed; /* Make the position fixed */
    top: 10%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for the element's own width and height */
    background-color: #282C34; /* Add a background color for visibility */
    padding-top: 20px;
    padding: 20px; /* Add some padding */
    border-radius: 10px; /* Optional: add some border radius for aesthetics */
    z-index: 1000; /* Optional: ensure the message is above other elements */
    border: 4px solid orange;
    color: white
  }
  
  @keyframes fadeOut {
    0%, 93% { /* Adjust this percentage based on how long you want the message to stay visible */
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

  .modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100; /* Ensure it appears above other content */
  }
  
  .modalContainer {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 90%;
    color: #333; /* Dark gray color for better readability */
  }
  
  .modalHeader h5, .modalContent, .modalFooter {
    color: #333; /* Ensures text in these elements is also dark gray for consistency */
  }
  
  .modalHeader, .modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .closeButton, .modalCloseButton {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
  }
  
  .modalContent {
    margin-top: 20px;
  }
  
  .letterSet.correct {
    background-color: green;
  }
  
  .letterSet.incorrect {
    background-color: red;
  }

  .hidden {
    display: none;
}

.buttonContainer{
  flex-direction: row;
}

.wordLengthSelection {
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers children (buttons) horizontally */
  align-items: center; /* Centers children vertically (if needed) */
  flex-wrap: wrap; /* Allows buttons to wrap in smaller screens */
  padding-bottom: 20px; /* Keeps existing spacing */
  width: 100%; /* Ensures it spans the full width of its container */
  margin: 0 auto; /* Centers the element within its parent */
}

@media (max-width: 768px) { /* adjust the value as needed */
    .mobile-spacing {
      margin-top: 20px; /* adjust the value as needed */
      width: auto;
    }
    .gameBoard {
        font-family: 'Arial, sans-serif';
        padding: 20px;
        max-width: 600px;
        margin: 0 auto;
        height: 100%; /* Add this line */
        flex-direction: column; /* Add this line */
        justify-content: space-between; /* Add this line */
    }
  }

  @media (max-width: 600px) {
    .messageContainer {
      border: 4px solid orange; /* Corrected syntax: combines width, style, and color */
      padding-top: 20px;
      margin-top: 60px; /* Adjust this value as needed */
      background-color: #282C34;
      color: white
    }
  }
  

  @media (max-width: 375px) {
    /* Adjustments for smaller devices like iPhone 6, 7, 8, X */
    .gameBoard {
      padding: 20px;
      max-width: 90%; /* Allow some margin on the smallest screens */
    }
  
    .letterSet {
      width: 40px; /* Slightly reduce size for smaller screens */
      height: 40px;
      font-size: 18px; /* Adjust font size for readability */
    }
  
    .inputStyle, .buttonCommon {
      padding: 12px 15px; /* Increase touch target size */
    }
  }

  @media (max-width: 414px) {
    /* Adjustments for larger devices like iPhone Plus models, iPhone 11 Pro Max, 12 Pro Max */
    .gameBoard {
      padding: 25px;
    }
  
    .letterSet {
      width: 45px;
      height: 45px;
      font-size: 19px;
    }
  
    /* Consider adding more specific styles for these devices if necessary */
  }

  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px),
  only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px), /* iPad Pro 10.5" */
  only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) { /* iPad Pro 12.9" */

  .gameBoard {
    padding: 50px; /* Increase padding to make the game board larger */
    max-width: 80%; /* Adjust max-width to utilize more screen space */
    font-size: 24px; /* Increase font size for better readability */
  }

  .letterSet {
    width: 60px; /* Increase width for larger touch targets */
    height: 60px; /* Increase height for larger touch targets */
    font-size: 24px; /* Increase font size for better visibility */
    margin: 10px; /* Adjust spacing between letter sets */
  }

  .inputStyle, .buttonCommon {
    padding: 15px 20px; /* Increase padding for easier interaction */
    font-size: 20px; /* Increase font size for better readability */
  }

  /* Increase size of other elements as needed */
}